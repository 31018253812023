<script>
  import { web, token } from "./stores.js";
  let app_user, app_pass;
  let saved_ok = false;
  let buscando = false;
  let store = window.localStorage;
  let saved_text = "";
  let saved_type = "";

  async function get_data() {
    buscando = true;
    let r = await fetch($web + "auth", {
      method: "POST",
      body: JSON.stringify({
        user: app_user,
        password: app_pass,
      }),
      headers: {
        "content-Type": "application/json",
      },
    }).catch(function (error) {
      store.removeItem("token-avl-admin");
      token.set(null);
      app_user = "";
      app_pass = "";
      saved_ok = true;
      saved_text = "Usuario o password incorrecto";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 1000);
    });
    if (r.status == 200) {
      r = await r.json();
      console.log(r);
      store.setItem("token-avl-admin", r.key);
      token.set(r.key);
    } else {
      store.removeItem("token-avl-admin");
      token.set(null);
      app_user = "";
      app_pass = "";
      saved_ok = true;
      saved_text = "Usuario o password incorrecto";
      saved_type = "alert-danger";
      setTimeout(function () {
        saved_ok = false;
      }, 1000);
    }
    buscando = false;
  }
</script>

<form
  class="form-signin"
  style="visibility: {!buscando ? 'visible' : 'hidden'};"
>
  <h4 style="text-align: center;color: darkgray; margin-bottom: 2.5rem; ">
    Device Manager 2.0
  </h4>
  <input
    type="text"
    class="form-control"
    placeholder="Usuario"
    required
    bind:value={app_user}
  />
  <input
    type="password"
    class="form-control mt-3"
    placeholder="Password"
    required
    bind:value={app_pass}
  />
  <div class="d-grid gap-2">
    <button
      style="background-color: #07ae4c; border-color: #07ae4c;"
      class="btn btn-primary btn-block mt-4"
      type="submit"
      on:click|preventDefault={() => {
        get_data();
      }}>Ingresar</button
    >
  </div>
  <p class="mt-5 mb-3 text-muted" style="text-align: center;">
    powered by VectorGPS
  </p>
</form>

{#if buscando}
  <div
    class=""
    style="visibility: {buscando
      ? 'visible'
      : 'hidden'};position: absolute;top: 50%; left: 50%; margin-top: -5em;"
  >
    <div
      class="spinner-border text-danger"
      style="width: 10rem; height: 10rem;position: absolute; left: 50%;  margin-left: -5em;"
      role="status"
    />
  </div>
{/if}

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

<style>
  .alert-top {
    position: fixed;
    top: 2rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  th {
    text-align: center;
    vertical-align: middle;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(94 233 113);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 4px;
  }
  .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #fff3cd;
  }
  .list-group.b0 {
    border-radius: 0;
  }

  :global(body) {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: #f5f5f5;
  }
  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
</style>
