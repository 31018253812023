<script>
  import { onMount } from "svelte";
  import { web, token, map_status } from "./stores.js";

  let mount_complete = false;
  onMount(() => {
    mount_complete = true;
  });

  let store = window.localStorage;
  let grupo_s = "",
    g_list = [],
    g_selected = -1,
    u_list = [],
    u_selected = -1,
    gu_list = [],
    buscando = false,
    saved_ok = false,
    saved_text = "",
    saved_type = "",
    serial_actual = "",
    eco_actual = "",
    buscando_unidad = false,
    buscando_lista = false;

  async function search_emp() {
    buscando_lista = true;
    let grupo_s_ = grupo_s;
    let list = await fetch(
      $web + "client_list_?name=" + grupo_s + "&key=" + $token
    );
    if (grupo_s_ != grupo_s) {
      console.log("change");
      if (grupo_s.length < 3) buscando_lista = false;
      return;
    }
    if (list.status == 428) {
      close_session();
      buscando_lista = false;
    } else if (list.status == 200) {
      list = await list.json();
      g_list = list;
      buscando_lista = false;
    } else {
      buscando_lista = false;
    }
  }
  $: {
    if (g_selected == -1) {
      if (grupo_s.length > 2) {
        search_emp();
      } else {
        g_list = [];
      }
    }
  }

  let serial_s = "";
  $: {
    if (serial_s.length > 0) {
      u_selected = -1;
      u_list = [];
      for (let x in gu_list) {
        if (
          gu_list[x].e.toLowerCase().search(serial_s) != -1 ||
          gu_list[x].s.toLowerCase().search(serial_s) != -1
        ) {
          u_list.push({
            t: gu_list[x].e + " (" + gu_list[x].s + ")",
            ...gu_list[x],
          });
        }
      }
    } else if (g_selected != -1) {
      u_selected = -1;
      u_list = [];
      for (let x in gu_list) {
        u_list.push({
          t: gu_list[x].e + " (" + gu_list[x].s + ")",
          ...gu_list[x],
        });
      }
    }
  }

  async function search_units() {
    buscando_lista = true;
    let list = await fetch(
      $web + "unit_list_?name=" + grupo_s + "&key=" + $token
    );
    if (list.status == 428) {
      close_session();
      buscando_lista = false;
    } else if (list.status == 200) {
      list = await list.json();
      gu_list = list;
      buscando_lista = false;
    } else {
      buscando_lista = false;
    }
  }

  let t_avl = "";
  let last_post;
  let last_data = {
    inputs: "",
    outputs_0: "",
    afc: [],
    client_data: {},
    logic_data: {},
  };
  async function get_avl_info(buscador_on = true) {
    if (buscador_on) {
      buscando_unidad = true;
      last_data = {
        inputs: "",
        outputs_0: "",
        afc: [],
        client_data: {},
        logic_data: {},
      };
    }
    let list = await fetch(
      $web + "getpos_?serial=" + serial_actual + "&key=" + $token
    );
    if (list.status == 428) {
      buscando_unidad = false;
      close_session();
    } else if (list.status == 200) {
      last_data = await list.json();
      map.setZoom(15);
      map.panTo({ lat: last_data.lat, lng: last_data.lon });
      if (typeof last_post != "undefined") {
        last_post.setMap(null);
      }
      last_post = new google.maps.Marker({
        position: { lat: last_data.lat, lng: last_data.lon },
        map: map,
        animation: google.maps.Animation.DROP,
      });
      if (typeof last_data.puls_info.brand != "undefined") {
        if (last_data.puls_info.brand == "gl200") t_avl = "queclink";
        if (last_data.puls_info.brand == "teltonika") t_avl = "teltonika";
        if (last_data.puls_info.brand == "atrack") t_avl = "atrack";
      } else {
        t_avl = "calamp";
      }
      buscando_unidad = false;
    } else {
      if (typeof last_post != "undefined") {
        last_post.setMap(null);
      }
      map.setZoom(5);
      map.fitBounds(bounds_gen);
      buscando_unidad = false;
    }
  }
  let task_general;
  $: {
    if (u_selected != -1 && u_list.length > 0) {
      eco_actual = u_list[u_selected].e;
      serial_actual = u_list[u_selected].s;
      t_avl = "";
      try {
        clearInterval(task_general);
      } catch (e) {}
      get_avl_info();
      task_general = setInterval(get_avl_info, 300 * 1000, false);
    }
  }

  function close_session() {
    g_selected = -1;
    u_selected = -1;
    g_list = [];
    u_list = [];
    gu_list = [];
    grupo_s = "";
    serial_s = "";
    store.removeItem("token-avl-admin");
    token.set(null);
  }

  let map;
  let bounds_gen;
  function initMap() {
    bounds_gen = new google.maps.LatLngBounds();
    bounds_gen.extend({ lat: 32.534353, lng: -117.123783 });
    bounds_gen.extend({ lat: 21.137926, lng: -86.740844 });
    bounds_gen.extend({ lat: 14.534659, lng: -92.231633 });
    map = new google.maps.Map(document.getElementById("map"), {
      center: { lat: 24.458489, lng: -102.217231 },
      zoom: 5,
      streetViewControl: false,
      mapTypeControl: false,
      fullscreenControl: false,
      styles: [
        {
          featureType: "administrative",
          elementType: "geometry",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.man_made",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "landscape.natural",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "poi",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "transit",
          stylers: [
            {
              visibility: "off",
            },
          ],
        },
        {
          featureType: "water",
          elementType: "geometry",
          stylers: [
            {
              visibility: "simplified",
            },
          ],
        },
      ],
    });
    map.fitBounds(bounds_gen);
  }
  $: {
    if ($map_status && mount_complete) {
      initMap();
    }
  }
</script>

<div class="container-fluid h-100 px-0">
  <div
    style="position: fixed; bottom: 5px; right: 5px; color: #95A5A6;z-index:20000;"
  >
    powered by VectorGPS
  </div>
  <div
    class="row h-100 g-0"
    style="visibility: {!buscando ? 'visible' : 'hidden'};"
  >
    <div class="col-md-2 h-100">
      <div class="card h-100 b0" style="border:0px;">
        <div class="d-flex flex-column h-100">
          <div
            class="card-header bg-dark text-white"
            style="padding: .3rem .5rem;"
          >
            <div class="d-flex justify-content-start align-items-center">
              {#if g_selected == -1}
                <div class="pe-2">
                  <i class="material-icons md-2">search</i>
                </div>
                <div class="flex-grow-1">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Buscar minimo 3 letras/números"
                    required
                    bind:value={grupo_s}
                    autofocus
                  />
                </div>
              {:else}
                <div class="pe-2">
                  <i class="material-icons md-2">search</i>
                </div>
                <div class="flex-grow-1">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Serial o Economico"
                    required
                    bind:value={serial_s}
                    autofocus
                  />
                </div>
              {/if}
            </div>
          </div>
          <div class="card-body flex-grow-1 overflow-auto" style="padding:0;">
            <div class="list-group h-100 b0">
              {#if g_list.length == 0 && u_list.length == 0 && buscando_lista == false}
                <div
                  style="height: 100%;display: flex;align-items: center;justify-content: center;"
                >
                  Ninguna
                </div>
              {:else if g_list.length == 0 && u_list.length == 0 && buscando_lista == true}
                <div
                  class=""
                  style="visibility: visible;position: absolute;top: 50%; left: 50%; margin-top: -5em;"
                >
                  <div
                    class="spinner-border text-danger"
                    style="width: 10rem; height: 10rem;position: absolute; left: 50%;  margin-left: -5em;"
                    role="status"
                  />
                </div>
              {/if}
              {#each g_list as item, c}
                <a
                  href="/{item}"
                  class="list-group-item list-group-item-action {g_selected == c
                    ? 'list-group-item-warning'
                    : ''}"
                  on:click|preventDefault={() => {
                    grupo_s = item;
                    g_selected = c;
                    g_list = [];
                    search_units();
                  }}>{item}</a
                >
              {/each}
              {#each u_list as item, c}
                <a
                  href="/{item.s}"
                  class="list-group-item list-group-item-action {u_selected == c
                    ? 'list-group-item-warning'
                    : ''}"
                  on:click|preventDefault={() => {
                    u_selected = c;
                  }}>{item.t}</a
                >
              {/each}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-10 h-100">
      <div class="card h-100 b0">
        <div class="d-flex flex-column h-100">
          <div class="card-header" style="padding: .3rem .5rem;">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex">
                {#if g_selected == -1}
                  <i class="material-icons bblue md-2">info</i> Información
                {:else}
                  <div>
                    <i class="material-icons md-2">domain</i>
                    <span style="font-size: 1em;">{grupo_s}</span>
                  </div>
                  <div>
                    <a
                      href="/{grupo_s}"
                      on:click|preventDefault={() => {
                        grupo_s = "";
                        serial_s = "";
                        g_selected = -1;
                        u_selected = -1;
                        u_list = [];
                        g_list = [];
                        gu_list = [];
                      }}><i class="material-icons md-2">highlight_off</i></a
                    >
                  </div>
                {/if}
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-secondary"
                  on:click|preventDefault={() => {
                    close_session();
                  }}>Cerrar sesión</button
                >
              </div>
            </div>
          </div>
          <div class="card-body flex-grow-1 overflow-auto" style="padding:0;">
            {#if u_selected == -1}
              <p class="m-3">Selecione una empresa o grupo y unidad</p>
            {:else if buscando_unidad == true}
              <div
                class=""
                style="visibility: visible;position: absolute;top: 50%; left: 50%; margin-top: -5em;"
              >
                <div
                  class="spinner-border text-danger"
                  style="width: 10rem; height: 10rem;position: absolute; left: 50%;  margin-left: -5em;"
                  role="status"
                />
              </div>
            {/if}
            <div
              class="row h-{u_selected != -1 && !buscando_unidad
                ? '100'
                : '0'} g-0"
              style="visibility: {u_selected != -1 && !buscando_unidad
                ? 'visible'
                : 'hidden'};"
            >
              <div class="col-md-4 h-100" style="overflow-y: auto;">
                <table class="table table-striped table-hover table-sm">
                  <tbody>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Económico</th
                      >
                      <td style="text-align:left;">{eco_actual}</td>
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Serial</th
                      >
                      <td style="text-align:left;">{serial_actual}</td>
                    </tr>
                    {#if last_data.logic_data.idproduct}
                      <tr>
                        <th class="ps-2" scope="row" style="text-align:left;"
                          >Tipo / (ID Unit)</th
                        >
                        <td style="text-align:left;"
                          >{last_data.logic_data.idproduct} / ({last_data
                            .logic_data.idunit})</td
                        >
                      </tr>
                    {/if}
                    {#if last_data.logic_data.ip}
                      <tr>
                        <th class="ps-2" scope="row" style="text-align:left;"
                          >IP</th
                        >
                        <td style="text-align:left;"
                          >{last_data.logic_data.ip} ({last_data.logic_data
                            .ip == "10.88.9.197"
                            ? "AFC Route"
                            : last_data.logic_data.ip.split(".")[0] == "10" &&
                              last_data.logic_data.ip.split(".")[1] == "197"
                            ? "Telcel Route"
                            : "AT&T Route"})</td
                        >
                      </tr>
                    {/if}
                    {#if last_data.logic_data.unit_name}
                      <tr>
                        <th class="ps-2" scope="row" style="text-align:left;"
                          >Número celular</th
                        >
                        <td style="text-align:left;"
                          >{last_data.logic_data.unit_name}</td
                        >
                      </tr>
                    {/if}
                    {#if last_data.client_data.clientcode}
                      <tr>
                        <th class="ps-2" scope="row" style="text-align:left;"
                          >Client / ERP / ID</th
                        >
                        <td style="text-align:left;"
                          >{last_data.client_data.clientcode} / {last_data
                            .client_data.erpid} / {last_data.client_data
                            .idclient}</td
                        >
                      </tr>
                    {/if}
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Ultima posición</th
                      >
                      <td
                        style="text-align:left;vertical-align: middle;{Math.round(
                          (moment().valueOf() - last_data.utime * 1000) /
                            1000 /
                            60
                        ) > 3600
                          ? 'color: red;'
                          : ''}"
                        >{!last_data.utime
                          ? "Ninguna"
                          : moment(last_data.utime * 1000).format(
                              "DD-MM-YYYY hh:mm"
                            ) +
                            " (" +
                            (Math.round(
                              (moment().valueOf() - last_data.utime * 1000) /
                                1000 /
                                60
                            ) > 3600
                              ? "+1dia)"
                              : Math.round(
                                  (moment().valueOf() -
                                    last_data.utime * 1000) /
                                    1000 /
                                    60
                                ) + "min)")}</td
                      >
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Ultima comunicación</th
                      >
                      <td
                        style="text-align:left;vertical-align: middle;{Math.round(
                          (moment().valueOf() - last_data.last_comm * 1000) /
                            1000 /
                            60
                        ) > 3600
                          ? 'color: red;'
                          : ''}"
                        >{!last_data.last_comm
                          ? "Ninguna"
                          : moment(last_data.last_comm * 1000).format(
                              "DD-MM-YYYY hh:mm"
                            ) +
                            " (" +
                            (Math.round(
                              (moment().valueOf() -
                                last_data.last_comm * 1000) /
                                1000 /
                                60
                            ) > 3600
                              ? "+1dia)"
                              : Math.round(
                                  (moment().valueOf() -
                                    last_data.last_comm * 1000) /
                                    1000 /
                                    60
                                ) + "min)")}</td
                      >
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Operador</th
                      >
                      <td style="text-align:left;"
                        >{!last_data.carrier
                          ? "Ninguna"
                          : last_data.carrier == "20"
                          ? "Telcel"
                          : last_data.carrier == "10" ||
                            last_data.carrier == "50" ||
                            last_data.carrier == "40" ||
                            last_data.carrier == "70" ||
                            last_data.carrier == "80" ||
                            last_data.carrier == "90"
                          ? "AT&T"
                          : last_data.carrier}</td
                      >
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Red</th
                      >
                      <td style="text-align:left;"
                        >{!last_data.network
                          ? "Ninguna"
                          : last_data.network}</td
                      >
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Nivel de señal</th
                      >
                      <td style="text-align:left;"
                        >{!last_data.rssi_ ? "Ninguna" : last_data.rssi_} (-{!last_data.rssi
                          ? "Ninguna"
                          : last_data.rssi}
                        dBm)</td
                      >
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Ignición</th
                      >
                      <td style="text-align:left;vertical-align: middle;"
                        ><i
                          class="material-icons md-2"
                          style="color: {last_data.ingnition == 0
                            ? 'red'
                            : 'green'};"
                          >toggle_{last_data.ingnition == 0 ? "off" : "on"}</i
                        >{last_data.ingnition == undefined
                          ? "Ninguna"
                          : last_data.ingnition == 0
                          ? " Off"
                          : " On"}</td
                      >
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Batería</th
                      >
                      <td
                        style="text-align:left;vertical-align: middle;color:{last_data.vin_ <
                        70
                          ? 'red'
                          : 'green'}"
                        ><i
                          class="material-icons md-2"
                          style="font-size: 1rem;color:{last_data.vin_ < 70
                            ? 'red'
                            : 'green'};">battery_charging_full</i
                        >{last_data.vin_ == undefined
                          ? "Ninguna"
                          : last_data.vin_}%</td
                      >
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Alimentación</th
                      >
                      <td
                        style="text-align:left;vertical-align: middle;color:{last_data.vout <
                        12
                          ? 'red'
                          : 'green'}"
                        >{last_data.vout == undefined
                          ? "Ninguna"
                          : last_data.vout}V</td
                      >
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Entradas</th
                      >
                      <td style="text-align:left;vertical-align: middle;">
                        <table style="width: 100%;">
                          <tr>
                            {#each last_data.inputs.split("") as item, c}
                              <td style="text-align:center;">
                                {7 - c}
                              </td>
                            {/each}
                          </tr>
                          <tr style="height: 1rem;">
                            {#each last_data.inputs.split("") as item}
                              <td style="text-align:center;"
                                ><i
                                  class="material-icons"
                                  style="font-size:1rem;color: {item == '0'
                                    ? 'red'
                                    : 'green'};">lens</i
                                ></td
                              >
                            {/each}
                          </tr>
                          <tr>
                            {#each last_data.inputs.split("") as item}
                              <td style="text-align:center;">
                                {item == "0" ? "Off" : "On"}
                              </td>
                            {/each}
                          </tr>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Salidas</th
                      >
                      <td style="text-align:left;vertical-align: middle;">
                        <table style="width: 100%;">
                          <tr style="height: 1rem;">
                            {#each last_data.outputs_0.split("") as item}
                              <td style="text-align:center;"
                                ><i
                                  class="material-icons"
                                  style="font-size:1rem;color: {item == '1'
                                    ? 'red'
                                    : 'green'};">lens</i
                                ></td
                              >
                            {/each}
                          </tr>
                          <tr>
                            {#each last_data.outputs_0.split("") as item}
                              <td style="text-align:center;">
                                {item == "1" ? "Off" : "On"}
                              </td>
                            {/each}
                          </tr>
                        </table>
                      </td>
                    </tr>
                    {#if last_data.afc_update != 0}
                      <tr>
                        <th class="ps-2" scope="row" style="text-align:left;"
                          >AFC Actualización</th
                        >
                        <td>
                          <table style="width: 100%;">
                            <tr>
                              <td
                                rowspan="2"
                                style="text-align:left;vertical-align: middle;{Math.round(
                                  (moment().valueOf() -
                                    last_data.afc_update * 1000) /
                                    1000 /
                                    60
                                ) > 3600
                                  ? 'color: red;'
                                  : ''}"
                                >{!last_data.afc_update
                                  ? "Ninguna"
                                  : moment(last_data.afc_update * 1000).format(
                                      "DD-MM-YYYY hh:mm"
                                    ) +
                                    " (" +
                                    (Math.round(
                                      (moment().valueOf() -
                                        last_data.afc_update * 1000) /
                                        1000 /
                                        60
                                    ) > 3600
                                      ? "+1dia)"
                                      : Math.round(
                                          (moment().valueOf() -
                                            last_data.afc_update * 1000) /
                                            1000 /
                                            60
                                        ) + "min)")}</td
                              >
                              {#each last_data.afc as item, c}
                                <td style="text-align:center;">
                                  {c + 1}
                                </td>
                              {/each}
                            </tr>
                            <tr style="height: 1rem;">
                              {#each last_data.afc as item}
                                <td style="text-align:center;"
                                  ><i
                                    class="material-icons"
                                    style="font-size:1rem;color: {item != 22 &&
                                    item != 23
                                      ? 'green'
                                      : 'red'};">lens</i
                                  ></td
                                >
                              {/each}
                            </tr>
                          </table></td
                        >
                      </tr>
                    {/if}
                    {#if last_data.temp1 || last_data.temp2 || last_data.temp3}
                      <tr>
                        <th class="ps-2" scope="row" style="text-align:left;"
                          >Temperaturas</th
                        >
                        <td style="text-align:left;vertical-align: middle;">
                          {last_data.temp1 ? last_data.temp1 + " °C" : "-"}
                          /
                          {last_data.temp2 ? last_data.temp2 + " °C" : "-"}
                          /
                          {last_data.temp3 ? last_data.temp3 + " °C" : "-"}
                        </td>
                      </tr>
                    {/if}
                    {#if last_data.puls_info}
                      <tr>
                        <th class="ps-2" scope="row" style="text-align:left;"
                          >Script / Firmware / Hardware</th
                        >
                        <td style="text-align:left;vertical-align: middle;">
                          {last_data.puls_info.scriptVersion +
                            "-" +
                            last_data.puls_info.configVersion}
                          /
                          {last_data.puls_info.firmware
                            ? last_data.puls_info.firmware
                            : "-"}
                          /
                          {last_data.puls_info.firmware2
                            ? last_data.puls_info.firmware2
                            : "-"}
                        </td>
                      </tr>
                    {/if}
                    <tr>
                      <th class="ps-2" scope="row" style="text-align:left;"
                        >Dirección</th
                      >
                      <td style="text-align:left;vertical-align: middle;">
                        {last_data.dir ? last_data.dir : "-"}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col-md-8 h-100">
                <div class="row h-50 g-0">
                  <div class="col-md-12 h-100">
                    <div class="h-100" id="map" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{#if saved_ok}
  <div class="alert-top alert {saved_type} mb-0 text-center" role="alert">
    <strong>{saved_text}</strong>
  </div>
{/if}

{#if buscando}
  <div
    class=""
    style="visibility: {buscando
      ? 'visible'
      : 'hidden'};position: absolute;top: 50%; left: 50%; margin-top: -5em;"
  >
    <div
      class="spinner-border text-danger"
      style="width: 10rem; height: 10rem;position: absolute; left: 50%;  margin-left: -5em;"
      role="status"
    />
  </div>
{/if}

<style>
  :root {
    --bs-body-font-size: 0.8rem;
    --bs-body-line-height: 0.9;
  }
  .h-0 {
    height: 0%;
  }
  .form-control {
    font-size: 0.8rem;
  }
  .btn {
    font-size: 0.9rem;
  }
  .alert-top {
    position: fixed;
    top: 2rem;
    left: 35%;
    width: 30%;
    z-index: 1050;
  }
  th {
    text-align: center;
    vertical-align: middle;
  }
  .material-icons {
    line-height: 0;
  }
  .material-icons.md-18 {
    font-size: 18px;
  }
  .material-icons.md-24 {
    font-size: 24px;
  }
  .material-icons.md-36 {
    font-size: 36px;
  }
  .material-icons.md-48 {
    font-size: 48px;
  }
  .material-icons.md-dark {
    color: rgba(0, 0, 0, 0.54);
  }
  .material-icons.md-dark.md-inactive {
    color: rgba(0, 0, 0, 0.26);
  }
  .material-icons.md-light {
    color: rgba(255, 255, 255, 1);
  }
  .material-icons.md-light.md-inactive {
    color: rgba(255, 255, 255, 0.3);
  }
  .material-icons.orange {
    color: #ff7043;
  }
  .material-icons.bblue {
    color: #26a69a;
  }
  .material-icons.green {
    color: rgb(94 233 113);
  }
  .material-icons.yei {
    color: #ff9800;
  }
  .material-icons.golden {
    color: #856404;
  }
  .material-icons.gray {
    color: #525a63;
  }
  .material-icons.red {
    color: #df1616;
  }
  i {
    vertical-align: middle;
    padding-bottom: 4px;
  }
  .list-group-item-warning.list-group-item-action:focus {
    color: #664d03;
    background-color: #fff3cd;
  }
  .list-group.b0 {
    border-radius: 0;
  }

  :global(body) {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-align: center;
    -ms-flex-pack: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    background-color: #f5f5f5;
  }
  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
  }
  .form-signin .checkbox {
    font-weight: 400;
  }
  .form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }
  .form-signin .form-control:focus {
    z-index: 2;
  }
</style>
